import React from 'react'

import ContactSection from '../components/ContactSection'
import FooterSection from '../components/FooterSection'
import Navbar from '../components/Navbar'
import PageBanner from '../components/PageBanner'
import SEO from '../components/SEO'
import {Link} from "gatsby"
import Layout from '../components/Layout'


const book = () => {
  return (
    <>
      <Layout>
        <SEO title="Book Free Consulting Session | Pandigita" />
        <Navbar bgColor="#EFF0F1" />
        <section className="booking-page-section">
          <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1>Book Free Consulting Session</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 mx-auto introduction">
              <div>Sure, we build software at a fair price. But our secret sauce is our consulting that is included: leveraging our experience and creativity to make your digital project more successful. Let's start with a consulting session on the house. Any topic that's pressing for you works:</div>
              <ul>
                <li>A challenge you're facing</li>
                <li>An idea you want to discuss</li>
                <li>"How feasible would it be to do X"</li>
                <li>"Can you give me your opinion on this issue with a contractor"</li>
              </ul>
            </div>
          </div>
          </div>
          <div className="booking-iframe-wrapper">
            <iframe
              src="https://calendly.com/pandigita/consulting"
              height="100%" width="100%" style={{border: "none"}}
            />
          </div>
        </section>
        <ContactSection className="contact-booking-page"/>
        <FooterSection/>
      </Layout>
    </>
  )
}

export default book
